import React from 'react';
import { EnquiryBasketModalStyles as S } from './EnquiryBasketModal.styles';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@stories/Components/Media/Modal/Modal';
import Button from '@stories/Components/Buttons/Button/Button';
const EnquiryBasketModal = () => {
    const basketState = useSelector((x) => x.basket);
    const translations = useSelector((x) => x.basket.enquiryBasketTranslations);
    const dispatch = useDispatch();
    return (React.createElement(S.Overrides, null,
        React.createElement(Modal, { size: "small", isActive: basketState.showEnquiryBasketPopup, onClose: () => dispatch({ type: 'BASKET/SHOW_ENQUIRY_BASKET_POPUP', payload: false }) },
            React.createElement(S.Container, null,
                React.createElement(S.Title, { size: "regular" }, translations['custom.gf.enquiryBasket.modal.title']),
                React.createElement(S.ButtonsContainer, null,
                    React.createElement("a", { href: translations['custom.gf.enquiryBasket.modal.proceedToEnquiryBasket.url'] },
                        React.createElement(Button, { branding: "secondary", title: translations['custom.gf.enquiryBasket.modal.proceedToEnquiryBasket'] })),
                    React.createElement(Button, { onClick: () => dispatch({
                            type: 'BASKET/SHOW_ENQUIRY_BASKET_POPUP',
                            payload: false,
                        }), "data-orange-outline": true, branding: "white", title: translations['custom.gf.enquiryBasket.modal.continueBrowsing'] }))))));
};
export default EnquiryBasketModal;
