import React, { useEffect } from 'react';
import { ModalStyles as S } from './Modal.styles';
import use100vh from '@hooks/use100vh';
import useBodyScrollLock from '@hooks/useBodyScrollLock';
import { ReactComponent as SvgClose } from '@img/icons/close-grey.svg';
import Loader from '@stories/Components/Misc/Loader/Loader';
const Modal = ({ closeTitle = 'Close', ...props }) => {
    use100vh();
    const lockRef = useBodyScrollLock(props.isActive);
    useEffect(() => {
        // document.querySelector('body')?.classList.add('is-fixed');
    }, []);
    useEffect(() => {
        // eslint-disable-next-line
        function handleClickOutside(event) {
            if (lockRef.current && !lockRef.current.contains(event.target)) {
                props.onClose();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [lockRef]);
    return (React.createElement(S.Wrapper, { visible: props.isActive, innerSize: props.size },
        React.createElement(S.Inner, { ref: lockRef },
            React.createElement(S.ContentOverflow, null, props.isLoading ? (React.createElement(S.LoadingOverlay, null,
                React.createElement(Loader, null))) : (React.createElement(React.Fragment, null,
                props.title && React.createElement(S.Title, null, props.title),
                props.children,
                React.createElement(S.Close, { onClick: props.onClose, title: closeTitle, "aria-label": closeTitle },
                    React.createElement(SvgClose, null))))))));
};
export default Modal;
