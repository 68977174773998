/* eslint-disable @typescript-eslint/no-unused-vars */
import { HireViewType, HydrateOption } from '@core/enums';
import { Grid } from '@helpers/grid';
import Button, { LinkButton } from '@stories/Components/Buttons/Button/Button';
import Select from '@stories/Components/Forms/Select/Select';
import Heading from '@stories/Components/Global/Typography/Heading';
import Modal from '@stories/Components/Media/Modal/Modal';
import ProductHeroCarousel from '@stories/Components/Media/ProductHeroCarousel/ProductHeroCarousel';
import React, { lazy, Suspense, useMemo, useState } from 'react';
import { ProductDetailsStyles as S } from './ProductDetails.styles';
import { WYSIWYGStyles } from '@stories/Components/Content/WYSIWYG/WYSIWYG.styles';
import EcommerceService from '@core/ecommerce/services/EcommerceService';
import withRedux from '@helpers/withRedux';
import withWidget from '@hoc/withWidget';
import { ReactComponent as ChevronRight } from '@img/icons/chevron-right.svg';
import nextDayDelivery from '@img/icons/next-day-delivery.svg';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import Loader from '@stories/Components/Misc/Loader/Loader';
import addBusinessDays from 'date-fns/addBusinessDays';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '@core/api/services/ApiService';
import EnquiryBasketModalWidget from '@stories/Widgets/EnquiryBasket/EnquiryBasketModal/EnquiryBasketModal';
import { ReactComponent as Cart } from '@img/icons/cart.svg';
const AddToBasket = lazy(() => import('@stories/Components/Forms/Basket/AddToBasket'));
const HireCard = lazy(() => import('@stories/Components/Cards/HireCard/HireCard'));
var BasketLayout;
(function (BasketLayout) {
    BasketLayout[BasketLayout["AddToBasket"] = 0] = "AddToBasket";
    BasketLayout[BasketLayout["Confirm"] = 1] = "Confirm";
})(BasketLayout || (BasketLayout = {}));
const ProductDetails = (props) => {
    const dispatch = useDispatch();
    const appState = useSelector((x) => x.app);
    const basketState = useSelector((x) => x.basket);
    const [selected, setSelected] = useState(null);
    const [modalActive, setModalActive] = useState(false);
    const [basketLayout, setBasketLayout] = useState(BasketLayout.AddToBasket);
    const [quantity, setQuantity] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [productQuestionAnswer, setAnswer] = useState('');
    const [errors, setErrors] = useState([]);
    const currentPrice = useMemo(() => {
        if (selected) {
            return selected.pricing.currentRateFormatted;
        }
        const lowestPrice = props.productVariants
            .filter((x) => x.pricing.currentRate !== null && x.pricing.currentRate > 0)
            .sort((a, b) => a.pricing.currentRate - b.pricing.currentRate)[0];
        return lowestPrice ? lowestPrice.pricing.currentRateFormatted : null;
    }, [props.productVariants, selected]);
    const basketItem = useMemo(() => {
        if (!selected || selected.pricing.currentRate === null) {
            return null;
        }
        return {
            hireViewType: HireViewType.ReadOnly,
            id: 1,
            imageUrl: selected.productImageUrl,
            title: props.title,
            variant: selected.productName,
            dayHirePeriodText: props.dayHirePeriodText,
            price: selected.pricing.currentRate,
            priceFormatted: selected.pricing.currentRateFormatted,
            priceSuffix: props.priceSuffix,
            startDateLabel: props.startDateLabel,
            endDateLabel: props.endDateLabel,
            startDate,
            endDate,
            allowDelete: false,
            quantity,
            productQuestion: {
                questionText: selected.productQuestionText,
                answerText: productQuestionAnswer,
            },
        };
    }, [selected, startDate, endDate, quantity]);
    const handleSelectChange = (index) => {
        const newProduct = props.productVariants[index - 1];
        if (!newProduct) {
            setSelected(null);
            return;
        }
        setSelected(newProduct);
        // We want to add 1 here so it starts the day AFTER the lead time days otherwise it'll be ON the last lead time day
        const leadTime = newProduct.leadTimeDays > 0 ? newProduct.leadTimeDays + 1 : 0;
        setStartDate(addBusinessDays(new Date(), leadTime));
        setEndDate(addBusinessDays(new Date(), leadTime));
    };
    const showAddToBasket = () => {
        if (!appState.isLoggedIn) {
            dispatch({
                type: 'APP/SET_LOGIN_MODAL',
                payload: {
                    loginType: LoginRedirectType.Default,
                },
            });
            return;
        }
        setBasketLayout(BasketLayout.AddToBasket);
        setModalActive(true);
    };
    const submitAddToBasket = async () => {
        if (!selected) {
            return;
        }
        dispatch({ type: 'BASKET/SET_LOADING', payload: 'add-to-basket' });
        const response = await EcommerceService.UpdateBasketItem({
            productId: selected.productId,
            quantity,
            hireDateStart: EcommerceService.getApiFormattedDate(startDate),
            hireDateEnd: EcommerceService.getApiFormattedDate(endDate),
            productQuestionAnswer: productQuestionAnswer,
        });
        dispatch({ type: 'BASKET/SET_LOADING', payload: 'none' });
        if (!response.status.success && response.validationErrors) {
            setErrors(response.validationErrors);
            return;
        }
        dispatch({ type: 'BASKET/SET_BASKET', payload: response.result });
        setBasketLayout(BasketLayout.Confirm);
    };
    const handleClose = () => {
        setErrors([]);
        setModalActive(false);
    };
    const addToEnquiryBasket = async () => {
        dispatch({
            type: 'BASKET/SET_LOADING',
            payload: 'add-to-enquiry',
        });
        try {
            const response = await ApiService.request({
                method: 'PUT',
                slug: 'update',
                controller: 'customer-enquiry',
                params: {
                    enquiries: basketState.enquiryBasket?.enquiries ?? [],
                    productId: selected?.productId ?? props.productId,
                },
            });
            if (response.status.success) {
                dispatch({
                    type: 'BASKET/SHOW_ENQUIRY_BASKET_POPUP',
                    payload: true,
                });
                dispatch({
                    type: 'BASKET/SET_ENQUIRY_BASKET',
                    payload: response.result,
                });
            }
        }
        finally {
            dispatch({
                type: 'BASKET/SET_LOADING',
                payload: 'none',
            });
        }
    };
    const renderBasketContent = () => {
        if (!selected) {
            return null;
        }
        switch (basketLayout) {
            default:
            case BasketLayout.AddToBasket: {
                return (React.createElement(Suspense, { fallback: React.createElement(Loader, { delay: 500 }) },
                    React.createElement(AddToBasket, { title: props.hireDatesTitle, productName: props.productGroupName, product: selected, price: `${props.pricePrefix} ${selected.pricing.currentRateFormatted} ${props.priceSuffix}`, quantityLabel: props.selectQuantity, quantity: quantity, setQuantity: setQuantity, startDateLabel: props.startDateLabel, startDate: startDate, onStartDateChanged: setStartDate, endDateLabel: props.endDateLabel, endDate: endDate, onEndDateChanged: setEndDate, buttonText: props.addToBasket, onClick: submitAddToBasket, errors: errors, excludedDates: appState.excludedDates, productQuestionAnswer: productQuestionAnswer, setAnswer: setAnswer, minimumHire: selected.pricing.minHireDays > 0 ? props.minimumHire : null })));
            }
            case BasketLayout.Confirm: {
                if (!basketItem) {
                    return null;
                }
                return (React.createElement(Suspense, { fallback: React.createElement(Loader, { delay: 500 }) },
                    React.createElement(HireCard, { hire: basketItem }),
                    React.createElement(S.ConfirmButtons, null,
                        React.createElement(LinkButton, { branding: "primary", title: props.proceedToCheckout, href: appState.checkoutOverviewUrl, onClick: () => dispatch({
                                type: 'BASKET/SET_LOADING',
                                payload: 'redirecting-to-checkout',
                            }) }),
                        React.createElement(S.ButtonLink, { onClick: handleClose },
                            props.continueBrowsing,
                            " ",
                            React.createElement(ChevronRight, null)))));
            }
        }
    };
    return (React.createElement(S.Wrapper, null,
        React.createElement(Grid.Default, null,
            React.createElement(S.Media, null,
                React.createElement(ProductHeroCarousel, { media: props.media, nextText: props.nextText, prevText: props.prevText })),
            React.createElement(S.Content, null,
                props.title && props.title.length ? (React.createElement(Heading, { visual: "h5", semantic: "h1" }, props.title)) : null,
                props.showEnquiryBasket ? (React.createElement(S.EnquiryCTAWrapper, null,
                    props.productVariants.length > 0 && (React.createElement(S.ProductSelect, null,
                        React.createElement(Select, { label: props.labelText, onClick: handleSelectChange, selectedOption: selected ? selected.productName : props.pleaseSelectText, options: [
                                {
                                    id: '-1',
                                    value: props.pleaseSelectText,
                                },
                                ...props.productVariants.map((x) => ({
                                    id: `${x.productId}`,
                                    value: x.productName,
                                })),
                            ] }))),
                    React.createElement(Button, { disabled: basketState.loading === 'add-to-enquiry', title: props.addToEnquiry, branding: "secondary", onClick: () => addToEnquiryBasket(), addon: React.createElement(Cart, null), addonPosition: "left" }))) : (React.createElement(React.Fragment, null,
                    appState.isLoggedIn && props.productVariants.length > 0 ? (React.createElement(S.Strong, null,
                        props.pricePrefix,
                        " ",
                        currentPrice,
                        " ",
                        props.priceSuffix)) : null,
                    props.productVariants.length > 0 ? (React.createElement(S.ProductSelect, null,
                        React.createElement(Select, { label: props.labelText, onClick: handleSelectChange, selectedOption: selected ? selected.productName : props.pleaseSelectText, options: [
                                {
                                    id: '-1',
                                    value: props.pleaseSelectText,
                                },
                                ...props.productVariants.map((x) => ({
                                    id: `${x.productId}`,
                                    value: x.productName,
                                })),
                            ] }),
                        React.createElement(Button, { title: props.addToBasket, branding: "primary", onClick: showAddToBasket, disabled: basketState.loading !== 'none' || !selected }),
                        !appState.isLoggedIn ? (React.createElement(Button, { title: props.loginButtonText, branding: "black", onClick: () => dispatch({
                                type: 'APP/SET_LOGIN_MODAL',
                                payload: {
                                    loginType: LoginRedirectType.Default,
                                },
                            }) })) : null)) : null,
                    props.delivery && selected && selected.leadTimeDays === 0 ? (React.createElement(S.Delivery, null,
                        React.createElement("img", { src: nextDayDelivery }),
                        React.createElement("small", null, props.delivery.text))) : null,
                    props.minimumHire && selected && selected.pricing.minHireDays > 0 ? (React.createElement(S.Warning, null,
                        React.createElement("strong", null,
                            props.minimumHire.prefix,
                            " - ",
                            selected.pricing.minHireDays,
                            ' ',
                            props.minimumHire.postfix),
                        props.minimumHire.message)) : null)),
                props.description && props.description.length ? (React.createElement(WYSIWYGStyles.Wrapper, { dangerouslySetInnerHTML: { __html: props.description } })) : null,
                props.features && props.features.length ? (React.createElement(S.Features, null, props.features.map((x) => (React.createElement("li", { key: x }, x))))) : null)),
        React.createElement(Modal, { title: basketLayout === BasketLayout.AddToBasket ? props.addToBasket : props.itemsAdded, isActive: modalActive, onClose: handleClose, size: "large", isLoading: basketState.loading !== 'none' }, renderBasketContent()),
        React.createElement(EnquiryBasketModalWidget, null)));
};
export default withWidget(withRedux(ProductDetails), 'ProductDetails', {
    hydrate: HydrateOption.Always,
});
