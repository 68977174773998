import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { ButtonReset } from '@helpers/global';
import { Device, from } from '@helpers/media';
import scrollbars from '@helpers/scrollbars';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
const Wrapper = styled.div `
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--100vh, 100vh);
  background: ${rgba(brand.black, 0.4)};
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease;
  overflow: hidden;

  @media ${from(Device.Tablet)} {
    padding: 24px;
  }

  ${({ visible }) => visible &&
    css `
      opacity: 1;
      pointer-events: all;
    `}

  ${({ innerSize }) => {
    switch (innerSize) {
        case 'small':
            return css `
          ${() => Inner} {
            max-width: 664px;

            @media ${from(Device.Tablet)} {
              min-width: 664px;
            }
          }
        `;
        case 'medium':
            return css `
          ${() => Inner} {
            height: 70vh;
            max-width: 804px;

            @media ${from(Device.Tablet)} {
              height: 50vh;
              min-width: 664px;
            }
          }
        `;
        case 'large':
            return css `
          ${() => Inner} {
            height: 100vh;
            max-width: 900px;

            @media ${from(Device.Tablet)} {
              width: 100%;
              height: 82vh;
              min-width: 664px;

              ${() => ContentOverflow} {
                display: flex;
                flex-direction: column;
                width: 100%;
              }
            }
          }
        `;
        default:
        case undefined:
            break;
    }
}}
`;
const Inner = styled.div `
  position: relative;
  z-index: 10000;
  background: ${brand.white};
  border-top: 6px solid ${brand.primary.base};
  padding: 30px 14px;
  width: 100%;
  max-width: 900px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbars};

  @media ${from(Device.Tablet)} {
    min-width: 664px;
    padding: 30px 40px;
  }

  @media ${from(Device.DesktopSmall)} {
    padding: 50px 100px;
  }
`;
const ContentOverflow = styled.div ``;
const Title = styled.span `
  font-size: 24px;
  line-height: 1.2;
  ${fonts.DaxPro['Bold']};
  color: ${brand.grey.grey20};
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${brand.grey.grey89};

  @media ${from(Device.Tablet)} {
    font-size: 35px;
  }
`;
const Close = styled.button.attrs({ type: 'button' }) `
  ${ButtonReset};
  position: absolute;
  top: 30px;
  right: 24px;
  width: 24px;
  height: 24px;

  @media ${from(Device.Tablet)} {
    top: 24px;
    width: 30px;
    height: 30px;
  }

  &:hover,
  &:focus,
  &:active {
    background: none;
    color: none;
  }
`;
const LoadingOverlay = styled.div `
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalStyles = {
    Wrapper,
    Inner,
    ContentOverflow,
    Title,
    Close,
    LoadingOverlay,
};
