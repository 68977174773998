import React, { useRef, useState } from 'react';
import SelectStyles from './Select.styles';
const Select = (props) => {
    const [active, setActive] = useState(false);
    const dropdownRef = useRef(null);
    React.useEffect(() => {
        // eslint-disable-next-line
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    return (React.createElement(SelectStyles.Wrapper, { isOpen: active },
        props.label && (React.createElement("label", null,
            props.label,
            props.required ? React.createElement("span", null, "*") : null)),
        React.createElement(SelectStyles.Element, { ref: dropdownRef, onClick: () => setActive(!active) },
            props.selectedOption,
            React.createElement(SelectStyles.Dropdown, null, props.options.map((x, index) => (React.createElement(SelectStyles.Item, { key: x.id, onClick: () => props.onClick(index) }, x.value)))))));
};
export default Select;
