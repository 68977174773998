import { MediaType } from '@core/enums';
import Flicking, { ALIGN } from '@egjs/react-flicking';
import { aspectRatio } from '@helpers/aspectRatio';
import { Device, until } from '@helpers/media';
import useImagesLoaded from '@hooks/useImagesLoaded';
import useMedia from '@hooks/useMedia';
import { ReactComponent as Chevron } from '@img/icons/chevron.svg';
import { ReactComponent as Play } from '@img/icons/play.svg';
import React, { useEffect, useRef, useState } from 'react';
import Iframe from '../Iframe/Iframe';
import Image from '../Image/Image';
import { ProductHeroCarouselStyles } from './ProductHeroCarousel.styles';
const ProductHeroCarousel = (props) => {
    const wrapperRef = useRef(null);
    const navRef = useRef(null);
    const heroRef = useRef(null);
    const imagesLoaded = useImagesLoaded(wrapperRef);
    const [index, setIndex] = useState(0);
    const isMobile = useMedia([until(Device.Tablet)], [true], false);
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, [isClient]);
    useEffect(() => {
        if (!imagesLoaded) {
            return;
        }
        navRef.current?.init();
        heroRef.current?.init();
    }, [imagesLoaded]);
    const renderTrackItems = () => {
        return props.media.map((x, i) => (React.createElement(ProductHeroCarouselStyles.MediaTrackItem, { key: `${x}-${i}`, isActive: i === index, onClick: () => heroRef.current?.moveTo(i) },
            x.mediaType === MediaType.Image ? (React.createElement(Image, { alt: x.description, layout: "intrinsic", objectPosition: "center", objectFit: "cover", width: 109, height: 109, src: x.mediaThumbnail || '', draggable: false })) : null,
            x.mediaType === MediaType.YouTube ? (React.createElement(React.Fragment, null,
                React.createElement(ProductHeroCarouselStyles.MediaItemIcon, null,
                    React.createElement(Play, null)),
                React.createElement(ProductHeroCarouselStyles.MediaThumbnail, { src: x.mediaThumbnail || '', alt: x.description }))) : null)));
    };
    const heroItems = props.media.map((x, i) => (React.createElement(ProductHeroCarouselStyles.MediaItem, { key: x.id },
        React.createElement(ProductHeroCarouselStyles.MediaItemInner, null,
            x.mediaType === MediaType.Image ? (React.createElement(React.Fragment, null,
                React.createElement(Image, { layout: "intrinsic", width: 665, height: 358, src: x.mediaSrc, alt: x.description, draggable: false, loading: i === 0 ? 'eager' : 'lazy' }))) : null,
            x.mediaType === MediaType.YouTube ? (React.createElement(React.Fragment, null, i === index ? (React.createElement(Iframe, { title: x.description, url: `${x.mediaSrc}?autoplay=1`, aspectRatio: aspectRatio(1920, 1080) })) : (React.createElement(Iframe, { title: x.description, url: x.mediaSrc, aspectRatio: aspectRatio(1920, 1080) })))) : null))));
    return (React.createElement(ProductHeroCarouselStyles.Wrapper, { ref: wrapperRef },
        React.createElement(ProductHeroCarouselStyles.MediaTrack, null,
            isClient && props.media.length > 4 ? (React.createElement(Flicking, { autoInit: false, interruptable: true, ref: navRef, horizontal: isMobile, defaultIndex: index, align: props.media.length === 5 ? ALIGN.CENTER : ALIGN.PREV, circular: props.media.length > 4 }, renderTrackItems())) : (React.createElement(ProductHeroCarouselStyles.MediaTrackGrid, null, renderTrackItems())),
            React.createElement(ProductHeroCarouselStyles.MediaTrackArrows, null,
                React.createElement(ProductHeroCarouselStyles.MediaTrackArrow, { disabled: props.media.length > 4 ? false : index === 0, onClick: () => heroRef.current?.prev(), "aria-label": props.prevText },
                    React.createElement(Chevron, null)),
                React.createElement(ProductHeroCarouselStyles.MediaTrackArrow, { disabled: props.media.length > 4 ? false : index === props.media.length - 1, onClick: () => heroRef.current?.next(), "aria-label": props.nextText },
                    React.createElement(Chevron, null)))),
        React.createElement(ProductHeroCarouselStyles.Media, null, isClient ? (React.createElement(Flicking, { ref: heroRef, autoInit: false, defaultIndex: index, align: ALIGN.CENTER, deceleration: 10, circular: props.media.length > 4, onMoveEnd: (e) => {
                setTimeout(() => {
                    // Why yes - one millisecond is all it takes for the incorrect value to suddenly be right. //
                    const correctIndex = e.currentTarget.currentPanel.index;
                    setIndex(correctIndex);
                    navRef.current?.moveTo(correctIndex);
                }, 1);
            } }, heroItems)) : (React.createElement(React.Fragment, null, heroItems[0])))));
};
export default ProductHeroCarousel;
