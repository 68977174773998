import { css } from 'styled-components';
import brand from './brand';
export default css `
  &::-webkit-scrollbar-track {
    background-color: ${brand.white};
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${brand.primary.base};
    border-radius: 0;
    cursor: pointer;
  }
`;
export const greyScrollbar = css `
  &::-webkit-scrollbar-track {
    background-color: ${brand.white};
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${brand.grey.grey76};
    border-radius: 4px;
    cursor: pointer;
  }
`;
