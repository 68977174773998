import ApiService from '@core/api/services/ApiService';
import UrlResolver from '@core/url-resolver/UrlResolver';
import format from 'date-fns/format';
class EcommerceService {
    async UpdateBasketItem(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'PUT',
            slug: 'order/basket-item',
            params: { ...params },
        });
        return response;
    }
    async DeleteBasketItem(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'DELETE',
            slug: 'order/remove-item',
            params: { ...params },
        });
        return response;
    }
    async UpdateOrder(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'PUT',
            slug: 'order/latest',
            params: { ...params },
        });
        return response;
    }
    async SubmitOrder(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'PUT',
            slug: 'order/submit-latest',
            params: { ...params },
        });
        return response;
    }
    GetDownloadPdfUrl(params) {
        return `/api/g42/hires/download-order?orderNumber=${params.orderNumber}`;
    }
    async extendHire(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'PATCH',
            slug: 'hires/extend',
            params: { ...params },
        });
        return response;
    }
    async offHire(params) {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'PATCH',
            slug: 'hires/offhire',
            params: { ...params },
        });
        return response;
    }
    async fetchLatestOrder() {
        const response = await ApiService.safeRequest({
            baseUrl: UrlResolver.getDomain(),
            controller: 'g42',
            method: 'GET',
            slug: 'order/latest',
        });
        if (response) {
            return response.result;
        }
        return null;
    }
    getApiFormattedDate(date) {
        return format(date, 'yyyy-MM-dd');
    }
}
export default new EcommerceService();
