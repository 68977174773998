import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import { ModalStyles } from '@stories/Components/Media/Modal/Modal.styles';
import styled from 'styled-components';
const Overrides = styled.div `
  display: contents;

  ${ModalStyles.Wrapper} {
    height: 100dvh !important;
  }

  ${ModalStyles.Close} {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;

      g {
        stroke: #575757 !important;
        opacity: 1;
      }
    }
  }
`;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(Paragraph) `
  color: #575757;
  ${fonts.DaxPro.Medium};
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 40px;
`;
const ButtonsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${ButtonStyles.Button} {
    min-width: 314px;

    &[data-orange-outline='true'] {
      ${ButtonStyles.Text} {
        ${fonts.DaxPro.Medium}
      }

      ${ButtonStyles.Inner} {
        border: 2px solid ${brand.accent.orange};
      }
    }
  }
`;
export const EnquiryBasketModalStyles = {
    Container,
    Overrides,
    Title,
    ButtonsContainer,
};
