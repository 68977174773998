import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled from 'styled-components';
import SvgWarning from '@img/icons/warning.svg';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import SelectStyles from '@stories/Components/Forms/Select/Select.styles';
import { ButtonReset } from '@helpers/global';
const Wrapper = styled.section ``;
const Media = styled.div `
  grid-column: span 12;

  @media ${from(Device.DesktopSmall)} {
    grid-column: span 5;
  }
`;
const Content = styled.div `
  grid-column: span 12;
  padding-bottom: 15px;

  @media ${from(Device.DesktopSmall)} {
    padding-bottom: 60px;
    grid-column: span 6;
  }

  h1 {
    color: ${brand.primary.base};
  }
`;
const Strong = styled.div `
  ${fonts.DaxPro['Medium']};
  font-size: 22px;
  line-height: 1.2;
  color: ${brand.grey.grey20};
  margin-bottom: 8px;

  @media ${from(Device.DesktopSmall)} {
    font-size: 30px;
  }
`;
const Delivery = styled.div `
  margin-bottom: 24px;

  img {
    display: block;
    width: 86px;
    height: 65px;
    margin-bottom: 8px;
  }

  small {
    display: block;
    ${fonts.DaxPro['Regular']};
    font-size: 14px;
    color: ${brand.black};
  }
`;
const Warning = styled.div `
  font-size: 14px;
  ${fonts.DaxPro['Medium']};
  color: ${brand.accent.orange};
  max-width: 430px;
  margin-bottom: 24px;
  background: url(${SvgWarning}) no-repeat top 5px left;
  background-size: 27px 25px;
  padding-left: 40px;

  strong {
    display: block;
    color: ${brand.grey.grey20};
  }
`;
const ProductSelect = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 12px;

  ${SelectStyles.Wrapper} {
    margin-right: 20px;
    margin-bottom: 24px;
  }

  ${ButtonStyles.Button} {
    margin-right: 20px;
    height: 50px;
    margin-bottom: 24px;
  }
`;
const Features = styled.ul `
  li {
    ${ParagraphStyles.RegularStyles};
    margin-bottom: 5px;
    padding-left: 26px;
    position: relative;

    &:before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: ${brand.primary.base};
      position: absolute;
      top: calc(0.5em - 3px);
      left: 0px;
    }
  }
`;
const ButtonLink = styled.button.attrs({ type: 'button' }) `
  ${ButtonReset};
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  ${fonts.DaxPro['Bold']};
  color: ${brand.grey.grey20};

  svg {
    width: 7px;
    height: 14px;
    margin-left: 7px;
  }

  &:hover,
  &:focus,
  &:active {
    background: none;
    color: ${brand.grey.grey20};
  }

  &:hover {
    text-decoration: underline;
  }
`;
const ConfirmButtons = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;
  padding-top: 50px;

  > * {
    margin-bottom: 30px;

    &:last-child {
      margin-right: unset;
    }
  }

  @media ${from(Device.MobileLarge)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const EnquiryCTAWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0px 30px 0px;

  ${SelectStyles.Wrapper} {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  ${ButtonStyles.Inner} {
    padding: 13px 15px;

    svg {
      path {
        fill: ${brand.white} !important;
      }
    }
  }
`;
export const ProductDetailsStyles = {
    Wrapper,
    Media,
    Content,
    Strong,
    Delivery,
    Warning,
    ProductSelect,
    Features,
    ButtonLink,
    ConfirmButtons,
    EnquiryCTAWrapper,
};
