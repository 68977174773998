import { useEffect, useState } from 'react';
export default function useImagesLoaded(ref) {
    const [imageLoaded, setImagesLoaded] = useState(false);
    useEffect(() => {
        if (ref.current === null) {
            return;
        }
        // Get all the images under the ref
        const images = Array.from(ref.current.querySelectorAll('img'));
        // Declare an async function that loops over the images
        // And creates a promise that is resolved when each of the images load
        async function asyncWork() {
            const promises = [];
            for (let index = 0; index < images.length; index++) {
                const image = images[index];
                if (image.complete) {
                    continue;
                }
                const promise = new Promise((resolve) => {
                    image.addEventListener('load', () => {
                        resolve(true);
                    });
                    image.addEventListener('error', () => {
                        resolve(true);
                    });
                });
                promises.push(promise);
            }
            // Await all the image loading promises
            await Promise.all(promises);
            // Set to true after this happens regardless of the values
            setImagesLoaded(true);
        }
        // Call the async
        asyncWork();
    }, []);
    // Return the state value
    return imageLoaded;
}
