import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
const use100vh = () => {
    const hasBound = () => window.getComputedStyle(document.documentElement).getPropertyValue('--100vh').length > 0;
    const handleResize = useRef(debounce(() => document.documentElement.style.setProperty('--100vh', `${window.innerHeight}px`), 200));
    useEffect(() => {
        if (hasBound()) {
            return;
        }
        handleResize.current();
        window.addEventListener('resize', handleResize.current);
        return () => window.removeEventListener('resize', handleResize.current);
    }, []);
    return;
};
export default use100vh;
