import brand from '@helpers/brand';
import { FlickingCss } from '@helpers/flickingStyles';
import { ButtonReset } from '@helpers/global';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
const Wrapper = styled.div `
  --slide-height: 127px;
  --slide-width: 109px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr var(---slide-height) 1fr;
  margin-bottom: 20px;
  ${FlickingCss};

  @media ${from(Device.Tablet)} {
    grid-template-columns: var(--slide-width) 1fr;
    grid-template-rows: auto;
    position: sticky;
    top: 30px;
  }

  @media ${from(Device.DesktopSmall)} {
    margin-bottom: 40px;
  }
`;
const MediaTrack = styled.div `
  overflow: hidden;
  display: grid;
  grid-template-rows: var(--slide-height) 44px;
  grid-gap: 12px;
  grid-row: 2;

  @media ${from(Device.Tablet)} {
    grid-template-rows: calc(var(--slide-height) * 4) 44px;
    grid-row: auto;
  }
`;
const MediaTrackGrid = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;

  @media ${from(Device.Tablet)} {
    flex-direction: column;
  }
`;
const MediaTrackArrows = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${from(Device.Tablet)} {
    justify-content: space-around;
  }
`;
const MediaTrackArrow = styled.button.attrs({ type: 'button' }) `
  ${ButtonReset};
  background-color: ${brand.primary.base};
  width: 44px;
  height: 44px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:nth-child(1) {
    transform: rotate(-90deg);

    @media ${from(Device.Tablet)} {
      transform: rotate(0deg);
    }
  }

  &:nth-child(2) {
    transform: rotate(90deg);
    margin-left: 6px;

    @media ${from(Device.Tablet)} {
      transform: rotate(180deg);
      margin-left: 0px;
    }
  }

  svg {
    width: 28px;
    height: 14px;
    stroke: ${brand.white};
    stroke-width: 2px;
  }
`;
const MediaTrackItem = styled.div `
  position: relative;
  height: var(--slide-width);
  width: var(--slide-width);
  padding: 4px;
  cursor: pointer;

  ${({ isActive }) => isActive
    ? css `
          border: 2px solid ${brand.primary.base};
        `
    : css `
          border: 2px solid transparent;
        `}
`;
const Media = styled.div `
  position: relative;
  margin-bottom: var(--grid-gap);
  background-color: ${brand.white};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 0 50px;

  @media ${from(Device.Tablet)} {
    margin-bottom: 0px;
    margin-left: var(--grid-gap);
  }
`;
const MediaItem = styled.div `
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  @media ${from(Device.Desktop)} {
    height: 600px;
  }
`;
const MediaItemInner = styled.div `
  height: 100%;
  flex-grow: 1;
  text-align: center;
  position: relative;

  > svg {
  }
`;
const MediaItemIcon = styled.div `
  width: 41px;
  height: 41px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
  background-color: ${brand.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 18px;
    height: 22px;
    fill: ${brand.primary.base};
    margin-left: 5px;
  }
`;
const MediaThumbnail = styled.img `
  object-fit: cover;
  object-position: center center;
  height: 100%;
`;
export const ProductHeroCarouselStyles = {
    MediaTrack,
    MediaTrackItem,
    MediaTrackArrows,
    MediaTrackArrow,
    Media,
    MediaItem,
    MediaItemInner,
    MediaItemIcon,
    MediaThumbnail,
    Wrapper,
    MediaTrackGrid,
};
